<div class="steppers-container row">
  <div class="col-md-12 col-lg-12 col-xxxl-12">

<nb-card>
    <nb-card-header>{{ls.strings[title_key]}}</nb-card-header>
        <nb-card-body>
           <button nbButton status="info" (click)="jumpToClientOrganization($event)">{{ls.strings.edit_client_info}}</button>

              <div class="col-sm-6">
                      <div class="form-group">
                        <p><b>{{ls.strings.denumire}}:&nbsp;</b><span>{{organization.denumire}}</span></p>
                    </div>
                  </div>
               <div class="col-sm-6">
                <div class="form-group">
                    <p><b>{{ls.strings.adresa}}:&nbsp;</b><span>{{workplace_address}};</span></p>
           </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <p><b>{{ls.strings.shipping_addres}}:&nbsp;</b><span>{{shipping_address}}</span></p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <p><b>{{ls.strings.billing_address}}:&nbsp;</b><span>{{billing_address}}</span></p>
                    </div>
                </div>
            <div class="col-sm-6">
                 <div class="form-group">
                    <p><b>{{ls.strings.reprezentant}}:&nbsp;</b><span>{{representative}}</span>
                </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                  <div class="exposed-wearers">
                      <p><b>{{ls.strings.number_unreturned_dosimeters}}:&nbsp;</b><span>{{lateDosimeters}}</span></p>
                      <button class="show_wearers" nbButton status="info" (click)="show_unreturned_dosi()">{{ls.strings.show_unreturned_dosi}}</button>
                  </div>
              </div>
          </div>
            <br>
             
             <nb-card-footer>
                <button class="generate" (click)="generateContract()"  nbButton hero status="primary">{{ls.strings.generate_contract}}</button>
              </nb-card-footer>
              <br>
              <br>
             <ngx-folder-broswer #browser [in_client_overview]="true"></ngx-folder-broswer>
        </nb-card-body>
    </nb-card>
    </div>
</div>

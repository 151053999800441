import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public strings: any;
  public lang:any="";
  public static initialized:boolean=false;
  mySubject : Subject<any> = new Subject<any>();
  myData : any ;
  
 
  constructor(private http:HttpClient) {
    console.log("---------------------------------- lang service")
    this.strings={};
    let lang=localStorage.getItem("lang")
    if(lang)
              this.lang=lang;
              else this.lang="en"
    this.http.get('./assets/strings/'+this.lang+'strings.json').subscribe( data => {
      this.strings=JSON.parse(JSON.stringify(data));
      LanguageService.initialized=true;
      console.log("---------------------------------- lang service done")
      this.setLang(this.strings);
    }, err=> { console.log("error comunicating");   });
   }
   
   public getString(id) {

  console.log(id)
     return this.strings[id];
   }
 changeLanguage(lang){
  localStorage.setItem("lang",lang)
  this.lang=lang;
  this.setLang(lang)
console.log("|||",this.lang)
  this.http.get('./assets/strings/'+lang+'strings.json').subscribe( data => {
    this.strings=JSON.parse(JSON.stringify(data));
    LanguageService.initialized=true;

  }, err=> { console.log("error comunicating");   });

 }
public getLang():Observable<any>{
  console.log("GETLANG")
   //return of(this.lang);
   return this.mySubject.asObservable()
 }



 setLang(data : any){
  this.myData = data;
  this.mySubject.next(this.myData);
 }

}

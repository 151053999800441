import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuard } from './@core/services/auth/auth.guard';
import { Maintenance } from './@core/services/auth/maintenance.guard';
import {NgxAuthModule} from './auth/auth/auth.module'
import { PreloadAllModules } from '@angular/router';
import { LanguageService } from './@core/services/language/language.service';
import { AuthorizeComponent } from './auth/components/authorize/authorize.component';
export const routes: Routes = [
  {
    path:'authorize/access/:id',
    component: AuthorizeComponent
  },
  {
    path: 'pages',
    
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth/auth.module')
      .then(m => m.NgxAuthModule),
   
  },
 /* {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      }, 
      {
        path: 'register',
        component: NbRegisterComponent,
      }, 
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },*/
  // { path: '', redirectTo: 'pages/client/organization-profile', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    {
    useHash: true,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
  providers: [ AuthGuard ]
})
export class AppRoutingModule {
}

<nb-card>
    <nb-card-header *ngIf="editMode && !wizzardMode">
        {{ls.strings.edit_monitoring_type}}
    </nb-card-header>
    <nb-card-header   *ngIf="!editMode && !wizzardMode">
     {{ls.strings.add_monitoring_type}}
    </nb-card-header>

    <nb-card-body>
        <nb-toggle status="primary" (change)="onChange($event)" [(ngModel)]="profile.active">{{ls.strings.activate_wearer}}</nb-toggle>
        <div class="col-sm-12">
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.organization}}<span *ngIf="showOrangeSpan" style="color:orange">*</span></p>
                    <nb-select fullWidth [disabled]="!editEnabled" [disabled]="disableSuborganizations" #suborganizationSelect (selectedChange)="selectionOrganizationChange($event)"
                        [(ngModel)]="profile.organization">
                        <nb-option *ngFor="let org of suborganizations" [value]="org.denumire">{{org.denumire}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_monitoring_types}}<span style="color:orange">*</span></p>
                    <nb-select fullWidth [disabled]="!editEnabled" #monitoringTypesSelect 
                        (selectedChange)="mtSelected($event)" [(ngModel)]="_mtIndex"> 
                        <nb-option *ngFor="let mt of monitoringTypes; let i = index"
                        [value]="i">{{mt.measurement}} ({{mt.description||''}})
                        </nb-option>
                    </nb-select>
                </div>
        
            </div>
        </div>


        <div class="col-sm-12">
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_dosimeter_makes}}<span *ngIf="showOrangeSpan" style="color:orange">*</span></p>
                    <nb-select fullWidth [disabled]="!editEnabled" #dosimeterMakesSelect
                        [(ngModel)]="profile.dosimeter_make" (selectedChange)="selectionMonitoringChange($event)">
                        <nb-option *ngFor="let dosi of dosimeterMake" [value]="dosi.value">{{dosi.value}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_dosimeter_types}}<span *ngIf="showOrangeSpan" style="color:orange">*</span></p>
                    <nb-select fullWidth [disabled]="!editEnabled" #dosimeterTypesSelect
                         (selectedChange)="selectionMonitoringTypeChange($event)">
                        <nb-option *ngFor="let dosimeter of selectedDosimeterMake.types" [value]="dosimeter">{{dosimeter.value}}
                        </nb-option>
                    </nb-select>
                </div>
            </div>
        </div>


        <div class="col-sm-12">
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_dosimeter_position}}</p>
                    <nb-select fullWidth [disabled]="!editEnabled" #positionSelect [(ngModel)]="profile.position"
                        (selectedChange)="selectionPositionChange($event)">
                        <nb-option *ngFor="let position of dosimeterPosition" [value]="position.value">
                            {{position.value}}</nb-option>
                    </nb-select>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="showDom">
                <div class="form-group">
                    <label for="inputDom" class="label">{{ls.strings.dom}}<span *ngIf="showOrangeSpan" style="color:orange">*</span></label>
                    <input required type="date" (change)="onDateChangeEvent($event)" nbInput fullWidth id="inputDom" [disabled]="disableSuborganizations"
                        [disabled]="!editEnabled" [(ngModel)]="profile.dom" [placeholder]="ls.strings.dom">
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="col-sm-6">
                <div class="form-group">
                    <p class="label">{{ls.strings.menu_monitoring_frequency}}<span *ngIf="showOrangeSpan" style="color:orange">*</span></p>
                    <nb-select class="frequency" fullWidth [disabled]="!editEnabled" #monitoringFrequencySelect [(ngModel)]="profile.monitoring_frequency"
                        (selectedChange)="selectionFrequencyChange($event)">
                        <nb-option *ngFor="let frequency of monitoringFequency" [value]="frequency.value">
                            {{frequency.value}}</nb-option>
                    </nb-select>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer *ngIf="!wizzardMode" style="text-align: center;">
        <button *ngIf="!editMode && !wizzardMode" nbButton hero status="primary" (click)="addMonitoringType()" [disabled]="!disabledButton()">{{this.ls.strings.add}} </button>
        <button nbButton *ngIf="editMode && !wizzardMode" hero status="primary" (click)="updateMonitoringType()">{{this.ls.strings.save_changes}}</button>
        <!-- <button nbButton *ngIf="wizzardMode" nbStepperNext status="primary" (click)="addBulkMonitoring()">{{this.ls.strings.next}}</button> -->
        <button nbButton *ngIf="!wizzardMode" hero status="primary" (click)="dismiss()">{{this.ls.strings.close}}</button>
     
    </nb-card-footer>
</nb-card>
<h1 id="title" class="title">{{ls.strings.login}}</h1>
<p class="sub-title">{{ls.strings.login_message}}</p>
<div class="d-flex justify-content-center">
<a style="margin-left:15px;height:36px" (click)="this.ls.changeLanguage('en')"><img style="height:inherit;cursor: pointer;" src='./assets/images/uk.png'></a>
<a style="margin-left:15px;height:39px" (click)="this.ls.changeLanguage('ro')"><img  style="height:inherit; cursor: pointer;" src='./assets/images/ro.png'></a>
</div>
<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ ls.strings.login_message }}</li>
  </ul>
</nb-alert>
<nb-stepper #loginStepper *ngIf="loaded && mfa_on">
  <nb-step [label]="asdf" hidden=true>
    <!-- step1 -->
    <form   #form="ngForm" aria-labelledby="title">

      <div class="form-control-group" *ngIf="loaded">
        <label class="label" for="input-email">{{ls.strings.user}}:</label>
        <input nbInput
               fullWidth
               [(ngModel)]="user.username"
               #username="ngModel"
               name="username"
               id="input-email"
               autocomplete="off" readonly onfocus="this.removeAttribute('readonly')"
               [placeholder]="this.ls.strings.user"
               fieldSize="large"
               autofocus
               [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : 'basic'"
             >
        <ng-container *ngIf="username.invalid && username.touched">
          <p class="caption status-danger" *ngIf="username.errors?.required">
           {{ls.strings.username_required}}
          </p>
          <p class="caption status-danger" *ngIf="username.errors?.pattern">
            {{ls.strings.username_should_exist}}
          </p>
        </ng-container>
        <span class="label-with-link">
          <label class="label" for="input-password">{{this.ls.strings.password}}:</label>
        </span>
        <input nbInput
               fullWidth
               [(ngModel)]="user.password"
               #password="ngModel"
               name="password"
               type="password"
               id="input-password"
               
               autocomplete="off" readonly onfocus="this.removeAttribute('readonly')"
               [placeholder]="this.ls.strings.password"
               fieldSize="large"
               [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
               [required]="getConfigValue('forms.validation.password.required')"
               [minlength]="getConfigValue('forms.validation.password.minLength')"
               [maxlength]="getConfigValue('forms.validation.password.maxLength')"
               [attr.aria-invalid]="password.invalid && password.touched ? true : null">
        <ng-container *ngIf="password.invalid && password.touched ">
          <p class="caption status-danger" *ngIf="password.errors?.required">
            {{ls.strings.password_required}}
          </p>
          <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
          {{ls.strings.password_should_contain}}
        <!--    from {{ getConfigValue('forms.validation.password.minLength') }}
            to {{ getConfigValue('forms.validation.password.maxLength') }}
            characters-->
          </p>
        </ng-container>
      </div>
    
     <!-- <div class="form-control-group accept-group">
        <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
      </div>
    -->
      <button nbButton
              fullWidth
              status="primary"
              size="large"
              (click)="mfAuthentication()"
              [disabled]="submitted || !form.valid"
              [class.btn-pulse]="submitted">
              {{ls.strings.mfa_login_next}}
      </button>
    </form>
  </nb-step>
  <nb-step [label]="bbb" hidden=true>
    <!-- step2 -->
    <form *ngIf="loaded" #form="ngForm" aria-labelledby="title">

      <div class="form-control-group">
       
        <span class="label-with-link">
          <label class="label" for="input-password">{{this.ls.strings.mfa_login_enter_code}}:</label>
        </span>
        <input nbInput
               fullWidth
               [(ngModel)]="user.mfacode"
               #mfacode="ngModel"
               name="mfacode"
               type="text"
               id="input-mfacode"
               [placeholder]="this.ls.strings.mfa_login_enter_code"
               fieldSize="large"
               autocomplete="off" readonly onfocus="this.removeAttribute('readonly')"
               [required]=true
               >
        
      </div>
    
     <!-- <div class="form-control-group accept-group">
        <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
      </div>
    -->
      <button nbButton
              fullWidth
              status="primary"
              size="large"
              (click)="performMFAuthentication()"
              [disabled]="submitted || !form.valid"
              [class.btn-pulse]="submitted">
              {{ls.strings.login}}
      </button>
    </form>

  </nb-step>
</nb-stepper>
<form *ngIf="loaded && !mfa_on" (ngSubmit)="authentification()" #form="ngForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">{{ls.strings.user}}:</label>
    <input nbInput
           fullWidth
           [(ngModel)]="user.username"
           #username="ngModel"
           name="username"
           id="input-email"
           autocomplete="off" readonly onfocus="this.removeAttribute('readonly')"
           [placeholder]="this.ls.strings.user"
           fieldSize="large"
           autofocus
           [status]="username.dirty ? (username.invalid  ? 'danger' : 'success') : 'basic'"
         >
    <ng-container *ngIf="username.invalid && username.touched">
      <p class="caption status-danger" *ngIf="username.errors?.required">
       {{ls.strings.username_required}}
      </p>
      <p class="caption status-danger" *ngIf="username.errors?.pattern">
        {{ls.strings.username_should_exist}}
      </p>
    </ng-container>
    <span class="label-with-link">
      <label class="label" for="input-password">{{this.ls.strings.password}}:</label>
    </span>
    <input nbInput
           fullWidth
           [(ngModel)]="user.password"
           #password="ngModel"
           name="password"
           type="password"
           id="input-password"
           [placeholder]="this.ls.strings.password"
           autocomplete="off" readonly onfocus="this.removeAttribute('readonly')"
           fieldSize="large"
           [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
           [required]="getConfigValue('forms.validation.password.required')"
           [minlength]="getConfigValue('forms.validation.password.minLength')"
           [maxlength]="getConfigValue('forms.validation.password.maxLength')"
           [attr.aria-invalid]="password.invalid && password.touched ? true : null">
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        {{ls.strings.password_required}}
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
      {{ls.strings.password_should_contain}}
    <!--    from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters-->
      </p>
    </ng-container>
  </div>

 <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>
  </div>
-->
  <button nbButton
          fullWidth
          status="primary"
          size="large"
       
          [disabled]="submitted || !form.valid"
          [class.btn-pulse]="submitted">
          {{ls.strings.login}}
  </button>
</form>

<a class="forgot-password caption-2" routerLink="../request-password">{{ls.strings.forgot_password}}</a>

<section class="another-action" aria-label="Register">
  {{ls.strings.not_registered}}<a class="text-link" routerLink="../register">{{ls.strings.register}}</a>
</section>
<br>

<nb-card-footer class="version">
  {{ls.strings.version}} {{appVersion}}
</nb-card-footer>
 
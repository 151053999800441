import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NbComponentStatus,NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { LocalDataSource, Ng2SmartTableComponent } from 'ng2-smart-table';
import { ActivatedRoute, Router } from '@angular/router';
import { AlfrescoService } from '../../../@core/services/alfresco/alfresco.service';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { ProcessBatchesComponent } from '../../asset-management/process-batches/process-batches.component';
import { BaseComponent } from '../../client/base/base-component';
import { ENV } from '../../../@core/services/data/data.conf';
import { SmartTableComponent } from '../../tables/smart-table/smart-table.component';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { EditAllocationComponent } from '../edit-allocation/edit-allocation.component';
import { ConfirmationComponent } from '../../client/modals/confirmation/confirmation.component';
import { OrganizationModel } from '../../../@core/models/organization.model';
import { AllocationModel } from '../../../@core/models/allocation.model';
import { DosimeterModel } from '../../../@core/models/dosimeter.model';
import { DoseRecordModel } from '../../../@core/models/doseRecord';
import { ExtraDosimetersComponent } from '../extra-dosimeters/extra-dosimeters.component';
import { DosimeterState } from '../../../@core/models/dosimeterState.model';
import { ExtraLabelComponent } from '../extra-label/extra-label.component';
import { Address } from '../../../@core/models/address.model';
import { NgxBarcodeComponent } from 'ngx-barcode';
import { Canvas } from 'leaflet';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { ReportService } from '../../../@core/services/report/report.service';
import { RecordOverviewComponent } from '../record-overview/record-overview.component';
import { Representative } from '../../../@core/models/representative.model';
import { ListpreviewReportsComponent } from '../listpreview-reports/listpreview-reports.component';

@Component({
  selector: 'ngx-list-preview',
  templateUrl: './listpreview.component.html',
  styleUrls: ['./listpreview.component.scss']
})
export class ListPreviewComponent extends BaseComponent implements OnInit {
  body: string;
  @Input() confirm: string;
  @Input() create: string;
  @Input() cancel: string;
  @ViewChild("barcode") barcode:NgxBarcodeComponent;
  @ViewChild("mycanvas") _canvas:HTMLElement;
  @ViewChild("batchesTable") batchesTable:Ng2SmartTableComponent;
 
  static eventObject: any;
  static objectsList: any[];
  static eventStatus: any;
  static eventDate:any;
  static eventActive:boolean;
  static client_minCode:any;
  static client_maxCode: any;
  // @Input("client_minCode") client_minCode;
  // @Input("client_maxCode") client_maxCode;
  private prevStatus:any;
  batchData:any[];
  valid: boolean;
  batchLabels:any={};
  currentIndex:number=0;
  currentLength:number=0;
  organization:OrganizationModel=new OrganizationModel();
  batchesSource: LocalDataSource = new LocalDataSource();
  imgsrc:any="";
  serial_img_data:any;
  servicesKeys: string[] = [];
  loadingData:Boolean=false;
  isAuditable=false;
  _type:String="null";
  
  private th_syle="border-top:solid #7295D2 1.0pt;  border-left:none;border-bottom:solid #7295D2 1.0pt;border-right:none;  background:#4472C4;padding:0in 5.4pt 0in 5.4pt;height:17.0pt;  color:  white;";
  private tr_style_1="font-size: 10px; background-color: #D0DBF0;";
  private tr_style_0="font-size: 10px; background-color: #white;";

  batchesFullColumns:any={

  }
  
  allocatedWearers=[];
  activeWearers: number=0;

  batchesSettings:any = {
    mode:'external',
    pager : {
      display: true,
      perPage: 100
    },
    rowClassFunction: (row) => {
      if (row.data.active==!true || row.data.active== undefined) {
        return 'disabled_line2';
      } 
      return '';
    },
    actions:{
      columnTitle:'',
      add: true,
      delete: true,
      edit: true,
      // select: true
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-plus"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
      enable: true
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    
    columns: {}
  
  };

  constructor(public data:DataService, public auth: AuthService,public toastrService: NbToastrService,
    private alfrescoService:AlfrescoService,
    private datePipe:DatePipe, private router:Router, private route:ActivatedRoute, private dataService:DataService,
    private sanitizer:DomSanitizer,
    private utilsService:UtilsService,
    private reportService:ReportService,
    public dialogService: NbDialogService, public ls:LanguageService) { 
    super(data, auth, toastrService)

  };

  private async initialize() {
    await this.loadData();
    await this.getServicesKey()
};

  private async reloadThisList(_index:any=0) {
    console.log("CLIENNNTT MIN IS ", ListPreviewComponent.client_minCode)
    let obj={ status: ListPreviewComponent.eventStatus, 
      allocStart: ListPreviewComponent.eventDate, 
      active: ListPreviewComponent.eventActive, 
      client_min: ListPreviewComponent.client_minCode || null, 
      client_max: ListPreviewComponent.client_maxCode || null};
    console.log("REloading batches", obj)
    let resp=await this.data.getBatches(this.auth.user.token, obj);
    console.log("NOW RESP IS ", resp)
    let arr=[];
    if (resp.batches.docs.length>0) {
      for (var k in resp.batches.docs) {
        arr.push(resp.batches.docs[k]._id);
      }
      
      ListPreviewComponent.objectsList=arr;
      if (_index>=arr.length) {_index=0;}
      ListPreviewComponent.eventObject=resp.batches.docs[_index]._id;

      console.log("ListPreview ---------  ",ListPreviewComponent.objectsList, ListPreviewComponent.eventObject);
      setTimeout(()=> {
        this.loadData();
      }, 50)
      
    } else {
      ListPreviewComponent.objectsList=[];
      ListPreviewComponent.eventObject=null;
        // navigate
        this.loadData();
    }
   
  }

  generateTableRow(obj, head?) {
    this.createLoadedDataColumnHead(this.batchData);
    const allowed=["monitoring_type", "dosimeterId", "comment","price","firstName","lastName","department", "allocStart", "allocEnd", "profession", "frequency"]
    let str="<tr>";
      for (var k in obj) {
        if (allowed.indexOf(k,0)!=-1) {
          if (head==false) {
            let val=obj[k];
            if (k.indexOf("alloc",0)!=-1) {
              val=this.utilsService.getFormattedDate(new Date(val))
            }
            str=str+"<td>"+val+"</td>";
          } else {
            str=str+"<th>"+k+"</th>";
          }
        }
      }
    str=str+"</tr>";
    return str;
  }

  getTabularContent(arr) {
    let str="";
    str=str+this.generateTableRow(arr[0], true);
    for (var k in arr) {
      let val=arr[k];
      
      str=str+this.generateTableRow(val,false);
    }
    str=str+"";
    return str;
  }
  svgString2Image(svgString, width, height, format, callback) {
    console.log("in string to image")
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
    // create canvas in memory(not in DOM)
    //var canvas = document.createElement('canvas');
    var canvas:any = document.getElementById("mycanvas");
    // get canvas context for drawing on canvas
    
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    
    var image = new Image();

    console.log("svgDaat"   , svgData)
    this.imgsrc=svgData;
    image.src=svgData;
    let parent = this;
    // later when image loads run this
    setTimeout(() => {
      var context = canvas.getContext('2d');
          // clear canvas
          context.clearRect(0, 0, width, height);
          // draw image with SVG data to canvas
          context.drawImage(image, 0, 0, width, height);
          // snapshot canvas as png
          var pngData = canvas.toDataURL('image/' + format);
          // pass png data URL to callback
          callback(parent, pngData);
    }, 1000);

}
  imageRenderCallBack(parent, val) {
    console.log(parent, "callback")
    parent.export(val);
    //this.imgsrc=val;
  }
  getTableHead(arr) {
    console.log("------------", this.batchesFullColumns);
    
    let str="";
    str=str+this.generateTableRow(arr[0], true);
    str="<tr>";
    let columns=this.batchesTable.grid.getDataSet().getColumns();
    for (var i=0;i<columns.length;i++) {
      str=str+"<th style='"+this.th_syle+"'>"+columns[i].title+"</th>";
    }
    str=str+"</tr>";
    return str;
  }
  getTableBody(arr) {
    console.log("yyyyyyyyyyyyyyyyyyyy", this.batchesTable.grid.getDataSet())
    let rows=this.batchesTable.grid.getDataSet().getRows();
    let str="";
    for (var i=0;i<rows.length;i++) {
      let m=i%2
      str=str+this.getTableRow(rows[i].cells,m);
    }
    return str;
  }
  getTableRow(cells,m=0) {
    let st=this.tr_style_0;
    if (m==1) { st=this.tr_style_1;}
    let str="<tr style='"+st+"'>";
    for (var i=0;i<cells.length;i++) {
      let val=cells[i].value;
      console.log("i: ",0, " cell value is  ",cells[i].value)
      if (cells[i].value!=null) {
        if (cells[i].value.key!=undefined) { val=cells[i].value.key} else { val=cells[i].value }
      }  else { val="-"}
      str=str+"<td>"+val+"</td>";
    }
    str=str+"</tr>"
    return str;
  }
  async startExport() {
    try {
    let ob=document.getElementById("barcode");
    let div=ob.getElementsByClassName("barcode")
    let sv:any=div[0].children[0];
    console.log("ob is ", sv, sv.g)
  
    this.svgString2Image(sv.outerHTML, 452, 82, 'png', this.imageRenderCallBack)
    } catch(e) { this.export('')}
  };

  async exportOne() {
    let columns=await this.utilsService.getDeliveryNoteFields();
    let dataObj:any=await this.reportService.exportOne(this.batchData, '', columns);
    let resp=await this.alfrescoService.quickGenerator(this.auth.user.token, {payload: dataObj});

    //cu raspunul de mai sus, facem un call nou ca sa generam PDF-ul pe care il afisam intr-o fereastra noua??
    //let resp2=await this.alfrescoService.getPDFRendition(resp.noderef);
    //let _url=this.alfrescoService.alf_url+ENV.API_GET_PDF_RENDITION+resp.noderef+"&alf_ticket="+this.alfrescoService._adminTicket;
    let _url=ENV.API_ALFRESCO_GENERATE_PDF + resp.noderef
    this.dataService.openPDFFileInWindow(_url, this.auth.user.token)
  };
  
  async export(img_data){
    console.log("redirect to service ");
    await this.exportOne();
    return;
    //prima data trebuie generat fisierul

    
  }

  onEdit (event) {
    console.log("on edit event is ", event, "Montoring_type is ", event.data)
    this.dialogService.open(EditAllocationComponent, {
      context: {
        allocation: event.data,
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(result => {
      console.log(result)
      if(result){
        //this.validate()
        this.loadData();
        this.openPrintLabelChoice(result) 
      }
    
    });
   
  }

  onCreate(event) {
    console.log(event, "batch data ", this.batchData[0])
    this.dialogService.open(ExtraDosimetersComponent, {
      context: {
        allocation: this.batchData[0]
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
        this.loadData();
      }
    })
  }
  openPrintLabelChoice(_allocation){
    this.dialogService.open(ExtraLabelComponent, {
      context: {
        allocation: _allocation
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
    
      }
    })
  }

  async openPrintManifestLabelChoice() {
    let obj= await this.getManifestLabelData();
    this.dialogService.open(ExtraLabelComponent, {
      context: {
        organization: obj
     
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
        
      }
    })
  };

  async openPrintManifestClientlabel() {
    let obj= await this.getManifestClientLabel();
    this.dialogService.open(ExtraLabelComponent, {
      context: {
        organization: obj
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
        
      }
    })
  };

  async getManifestLabelData() {
    let ob:any=this.batchData[0]
    console.log("ob is ", ob)
    let client= await this.dataService.getOrganizationDetails(ob.organizationId, this.auth.user.token)
    let suborg= await this.dataService.getOrganizationDetails(ob.suborganizationId, this.auth.user.token);
    console.log("client is ", client);
    console.log("department is ", suborg)
    let obj:any={};
    obj.client=client.docs[0].denumire;
    obj.department=suborg.docs[0].denumire;
    if (obj.client==obj.department) { obj.client="";}
    obj.organization_code=suborg.docs[0].organization_code;
    obj.monitoringKey=ob.monitoringKey;
    
    let clientRep: Representative=client.docs[0].representative;
    let depRep: Representative=suborg.docs[0].representative;
    let reprs:Representative=suborg.docs[0].shipping_representative;
    obj.representative=(reprs != null && reprs.nume != "-") ? reprs.nume : depRep.nume;
    // if(reprs.nume == '-' && depRep.nume == '-') {
    //   obj.representative=clientRep.nume
    // } else if (reprs.nume == '-' && depRep.nume != '-') { 
    //   obj.representative=depRep.nume
    // } else {
    //   obj.representative=reprs.nume
    // }
    let addy:Address=suborg.docs[0].shipping_address;
    console.log("shipping address is ", addy)
    obj.shipping_type=addy.shipping_type;
    obj.shipping_key=obj.shipping_type;
    obj.city=addy.city;
    obj.zipCode=addy.zipCode;
    //obj.address=addy.street+" "+addy.streetNumber;
    //obj.building=addy.floor+", "+addy.apartment;
    obj.street=addy.street;
    obj.streetNumber=addy.streetNumber;
    obj.floor=addy.floor,
    obj.buildingName= addy.buildingName;
    obj.apartment=addy.apartment;

    obj.country=addy.country;
    obj.county=addy.county;
    obj.line1=addy.line1;
    obj.line2=addy.line2;
    obj.line3=addy.line3;
    obj.line4=addy.line4;
    obj=this.utilsService.cleanupUndefinedStringValues(obj)
    console.log("print label obj client is ", obj)
    
    return obj;
  }
  async printShippingLabel() {
    let obj= await this.getManifestLabelData()
    this.openPrintManifestLabelChoice();
    //this.openPrintListLabelConfirmation(obj);
  };

  async getManifestClientLabel() {
    let ob:any=this.batchData[0];
    console.log("ob is ", ob)
    let client=await this.dataService.getOrganizationDetails(ob.organizationId, this.auth.user.token);
    let suborg= await this.dataService.getOrganizationDetails(ob.suborganizationId, this.auth.user.token);
    console.log("client is ", client);
    let obj:any={};
    const isClientNull = (client == null || client == undefined || client.docs == null || client.docs == undefined || client.docs.length == 0);
    const isSuborgNull = (suborg == null || suborg == undefined || suborg.docs == null || suborg.docs == undefined || suborg.docs.length == 0);
    
    obj.client= isClientNull ? '' : client.docs[0].denumire;
    obj.monitoringKey=ob.monitoringKey;
    //if (obj.client==obj.department) { obj.client="";}
    //obj.client=obj.department;
    obj.department="";
    obj.organization_code= isClientNull ? '' : client.docs[0].organization_code;
    let clientRep: Representative = client.docs[0].representative;
    let reprs:Representative= isClientNull ? null : client.docs[0].shipping_representative;
   //obj.representative= ( reprs.nume != '-') ? reprs.nume : clientRep.nume;
    obj.representative= ( reprs != null && reprs.nume != "-") ? reprs.nume : clientRep.nume;
    let adrs:Address=isClientNull ? null : client.docs[0].shipping_address;
    let adr:Address= isClientNull ? null : client.docs[0].workplace_address;
    console.log("shipping address is ", adrs)
    obj.shipping_type= (adrs != null) ? String(adrs.shipping_type) : String('');
    obj.shipping_key=obj.shipping_type;
    console.log("string de empty este ..." + String(''));
    obj.city=(adrs != null) ? adrs.city : '';
    obj.zipCode=(adrs != null) ? adrs.zipCode : '';
    //obj.address=(adrs != null) ? adrs.street+" "+adrs.streetNumber : '';
    //obj.building=(adrs != null) ? adrs.floor+", "+adrs.apartment: '';
    obj.street=adrs.street;
    obj.streetNumber=adrs.streetNumber;
    obj.floor=adrs.floor,
    obj.buildingName= adrs.buildingName;
    obj.apartment=adrs.apartment;
    
    obj.country=(adrs != null) ? adrs.country: '';
    obj.county=(adrs != null) ? adrs.county: '';
    obj.line1=adrs.line1;
    obj.line2=adrs.line2;
    obj.line3=adrs.line3;
    obj.line4=adrs.line4;
    obj=this.utilsService.cleanupUndefinedStringValues(obj)
    console.log("print label obj client is ", obj)
    return obj;
    
  }

 async  printClientShippingLabel(){
    let obj= await this.getManifestClientLabel();
    this.openPrintManifestClientlabel()
   // this.openPrintListLabelConfirmation(obj);
  };

  openPrintListLabelConfirmation(_org) {
    this.dialogService.open(ConfirmationComponent, {
      context: {
        body: this.ls.strings.print_label,
        confirm: this.ls.strings.print_shipping_label,
        cancel: this.ls.strings.cancel
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
        this.openPrintManifestLabelChoice()
      }
    })

  }
  visibleShipment():boolean {
    let bol=true;
    if( ListPreviewComponent.eventStatus =="shipped") { return false}

    return bol;
  };

  async getServicesKey(): Promise<void> {
    console.log("BD is ", this.batchData)

    try {
      let client=await this.dataService.getOrganizationDetails(this.batchData[0].organizationId, this.auth.user.token);
      let services  = client.docs[0].services;
      for (let service of services) {
      this.servicesKeys.push(service.key)
      
       }
       this.servicesKeys = services.map(service =>service.key);
    } catch(e) {console.log("empty batch[0]")}
  };

  async deleteAllocation(alloc){
    let resp = await this.data.deleteAllocation(alloc._id, this.auth.user.token, alloc)

    if(resp.status == 200){
      this.loadData();
      this.showToast('success', this.ls.strings.success, '');
    }
    else this.showToast('danger', this.ls.strings.danger, '');
  }

  onDelete(event) {
    this.dialogService.open(ConfirmationComponent, {
      context: {
        body: this.ls.strings.delete_confirmation,
        confirm: this.ls.strings.yes,
        cancel: this.ls.strings.cancel
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(result => {
      if(result == 'confirm'){
        this.deleteAllocation(event.data);
        
      }
    })
  }


async ship() {
  if (this.loadingData) { return }

  if (!this.servicesKeys || this.servicesKeys.length === 0) {
    await this.getServicesKey();
  }
  
 let _dm=new DoseRecordModel();
 let _d=new DosimeterModel();
 let collectionNames= [_dm.collection_name, _d.collection_name];
 console.log("THIS BATCH DATA IS ", this.batchData)
 this.loadingData=true;
 let respDoseRecord = await this.data.updateMultipleCollections(this.auth.user.token, this.batchData, { status: "shipped", valid: "true", state: "shipped" }, collectionNames);
if (respDoseRecord[0].status == 200 ) {
  if (respDoseRecord[0].failed.length==0) {
    this.showToast("success", this.ls.strings.success,'');


    //send the bacth with allocations for notification
    // let columns=await this.utilsService.getDeliveryNoteFields();
    // let dataObj:any=await this.reportService.exportOne(this.batchData, '', columns);
    // console.log("DATA OBJ IS ", dataObj)
    // let resp=await this.alfrescoService.quickGenerator(this.auth.user.token, {payload: dataObj});
    
    // dataObj.noderef=resp.noderef;
    // this.reportService.generateShippedDosimetersNotifications(dataObj, this.batchData)
    // console.log("new notification is ", dataObj)
    //end 
    
    this.openPrintListLabelConfirmation(null);
    let ind=ListPreviewComponent.objectsList.indexOf(ListPreviewComponent.eventObject);
    this.reloadThisList(ind);
    this.loadingData=false;
  } else if (respDoseRecord[0].failed.length>0) {
    this.showToast("warning", this.ls.strings.warning, this.ls.strings.update_failed_for+": "+Array(respDoseRecord[0].failed).toString());
  }
} 
else {
  this.showToast("warning", this.ls.strings.warning,''); 
}
this.loadingData = false;
}

private selectedRows: any;

// private async createLoadedDataColumnHead(head) {
//   let allowed = await this.utilsService.getDeliveryNoteFields();
  
//  this.batchesFullColumns={};
//   for (var i=0;i<allowed.length;i++) {
//     let k=allowed[i]
//     let isDate=k.indexOf("alloc",0)==0;
//     let isComment=k.indexOf('comment',0)==0;
//     let isNrCrt=k.indexOf("nrCrt",0)==0;
//     let _type="string";
//     if (isDate) _type="date";

//     this.batchesFullColumns[k]= 
//     { title: this.ls.strings[k]||k,
//     type: _type,
//     valuePrepareFunction:(d)=> {
//         var nd: any =d;
//         if (isDate)
//         try{nd = this.utilsService.getFormattedDate(new Date(d))} catch (e){ nd=d; }
//         if (isComment) 
//         try { nd=d.value } catch(e) { nd=d;}
//         return nd;
//        } 
//      }
//   }
// }

private async createLoadedDataColumnHead(head) {
  let allowed = await this.utilsService.getDeliveryNoteFields();

  this.batchesFullColumns = {};
  for (var i = 0; i < allowed.length; i++) {
    let k = allowed[i];
    let isDate = k.indexOf("alloc", 0) === 0;
    let isComment = k.indexOf('comment', 0) === 0;
    let isNrCrt = k.indexOf("nrCrt", 0) === 0;
    let isDosimeterId = k === 'dosimeterId';
    let _type = "string";
    if (isDate) _type = "date";
    if (isDosimeterId) _type = "html";

    this.batchesFullColumns[k] = {
      title: this.ls.strings[k] || k,
      type: _type,
      valuePrepareFunction: (d, row) => {
        let nd: any = d;
        if (isDate) {
          try {
            nd = this.utilsService.getFormattedDate(new Date(d));
          } catch (e) {
            nd = d;
          }
        } else if (isComment) {
          try {
            nd = d.value;
          } catch (e) {
            nd = d;
          }
        } else if (isDosimeterId) {
          return this.utilsService.getAuditLink(this.isAuditable, this._type, d,row,  this.auth.isSystemRole())
        }
        return nd;
      }
    };
  }
}


private async createLoadedDataColumnHeadOld(head) {
  let allowed = await this.utilsService.getDeliveryNoteFields();
  
 this.batchesFullColumns={};
  for (var k in head) {
    let isDate=k.indexOf("alloc",0)==0;
    let isComment=k.indexOf('comment',0)==0;
    let _type="string";
    if (isDate) _type="date";
    if (allowed.indexOf(k)!=-1) {
    this.batchesFullColumns[k]= 
    { title: this.ls.strings[k]||k,
    type: _type,
    valuePrepareFunction:(d)=> {
        var nd: any =d;
        if (isDate)
        try{nd = this.utilsService.getFormattedDate(new Date(d))} catch (e){ nd=d; }
        if (isComment) 
        try { nd=d.value } catch(e) { nd=d;}
        return nd;
       } 
     }
   } 
}
}

onUserRowSelect(event) {
  this.selectedRows =event.selected;
  console.log("SELECTED ROWS", this.selectedRows)
}

protected async reinit(ob: any): Promise<any> {
  console.log(" listpreview must reinit with ", ob)
  this._type='doserecord'
  this.isAuditable= await this.utilsService.getIsAuditable(this._type);
     await this.initialize();
      this.ls.mySubject.subscribe(message => {
        this.batchesSettings = { columns: {} }
      });
      setTimeout(() => {
        this.batchesSettings  = Object.assign({},  {
          mode:'external',
          pager : {
            display: true,
            perPage: 100
          },
          rowClassFunction: (row) => {
            if (row.data.active==!true) {
              return 'disabled_line';
            } 
            return '';
          },
          actions:{
            columnTitle:'',
            add: true,
            delete: true,
            edit: true,
            // select: true
          },
          add: {
            addButtonContent: '<i class="nb-plus"></i>',
            createButtonContent: '<i class="nb-plus"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmCreate: true,
          },
          edit: {
            editButtonContent: '<i class="nb-edit"></i>',
            saveButtonContent: '<i class="nb-checkmark"></i>',
            cancelButtonContent: '<i class="nb-close"></i>',
            confirmSave: true,
            enable: true
          },
          delete: {
            deleteButtonContent: '<i class="nb-trash"></i>',
            confirmDelete: true,
          },
          
          columns: this.batchesFullColumns
        } );

      }, 200);
  
    }

    scrollList(d) {
      console.log("scrolling direction ",d);
      let ind=ListPreviewComponent.objectsList.indexOf(ListPreviewComponent.eventObject);
      let nind=ind+d;
      if (nind<0) { nind=ListPreviewComponent.objectsList.length-1;}
      else 
      if (nind>ListPreviewComponent.objectsList.length-1) { nind=0;}
      ListPreviewComponent.eventObject=ListPreviewComponent.objectsList[nind];
      this.reinit(null)
    };

  
async loadData() {
  if (ListPreviewComponent.eventObject==null) {
    this.router.navigateByUrl('pages/admin/manifest');
    return;
  }
  this.currentIndex=ListPreviewComponent.objectsList.indexOf(ListPreviewComponent.eventObject)+1;
  this.currentLength=ListPreviewComponent.objectsList.length;
  console.log("EVENT OBBBBJ is ", ListPreviewComponent.eventObject)
  let obj={ client: ListPreviewComponent.eventObject.client, 
    department: ListPreviewComponent.eventObject.department, 
    client_min: ListPreviewComponent.client_minCode || null,
    client_max: ListPreviewComponent.client_maxCode || null, /*status: ListPreviewComponent.eventStatus,*/ 
    allocStart: ListPreviewComponent.eventObject.allocStart, 
    monitoring_type: ListPreviewComponent.eventObject.monitoring_type};
  let resp=await this.data.getBatcheDetails(this.auth.user.token, obj);
  if (resp.batches.docs.length>0) {
  //this.createLoadedDataColumnHead(resp.batches.docs[0]);
  this.processBatch(resp.batches.docs);
  } else {
  this.processBatch([])
  }
};


private concatenateFields(ar) {
  for (var i in ar) {
    ar[i].wearPeriod=this.formatDate(ar[i].wearStartDate)+"-"+this.formatDate(ar[i].wearEndDate);
    ar[i].fullName=ar[i].lastName+" "+ar[i].firstName;
   
      if (ar[i].comment==undefined || ar[i].comment==null) { ar[i].comment="-"; console.log("overwriting dose value for ", i)};
    

     //MULTIPLE COMM
     if (!Array.isArray(ar[i].comments )) {
      ar[i].comments = [];
    }
    ar[i].comments.value = ar[i].comments.map(commentObj => commentObj.value).join(', ');
    if (!ar[i].comments.value) {
      ar[i].comments.value = "-";
    }

    ar[i].nrCrt=Number(i)+1;
  }
  console.log("final concatenated fields is --------------- ", ar)
  return ar;
 };


 private formatDate(d) {
  return this.datePipe.transform(new Date(d), 'dd.MM.yyyy')
};

 private async processBatch(arr) {
   //this.batchData=[];
   if (arr.length>0) {
   this.batchData=arr;
   this.batchData=this.concatenateFields(this.batchData);
    this.batchLabels=Object.assign([], this.batchData[0]); 
    this.countActiveWearers(this.batchLabels)
   console.log("load data is ", this.batchData);

   await this.createLoadedDataColumnHead(this.batchData[0]);
   this.batchesSettings.columns=this.batchesFullColumns;
   this.batchesSource.load(this.batchData);
  //  this.reinit(null);
   this.batchesSource.refresh();

   } else {
    this.batchesSource.load([]);
    this.batchesSource.refresh();
   }
 };

 async countActiveWearers (ob) {
  let resp = await this.data.countActiveWearers({ query: {active: true, monitoring_type: ob.monitoring_type, suborganizationId: ob.suborganizationId,
  dosimeter_type: ob.dosimeter_type, dosimeter_make: ob.dosimeter_make }}, this.auth.user.token);
  this.activeWearers=resp.wearers;
 }

}

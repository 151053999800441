import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { DataService } from '../data/data.service';
import { AlfrescoService } from '../alfresco/alfresco.service';
import { AuthService } from '../auth/auth.service';
import { EncryptService } from '../encrypt/encrypt.service';
@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  private heads: HttpHeaders;
  private label_template:any;
  private selectedPrinter:any;
  constructor(private http:HttpClient, private dataService:DataService, private alfrescoService:AlfrescoService, 
    private encryptService:EncryptService,
    private auth:AuthService) {
    this.heads=new HttpHeaders({
      'Access-Control-Allow-Origin': "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
    })

   }

  public setPrinter(printerObj:any) {
    this.selectedPrinter=printerObj;
    this.setTemplate(this.selectedPrinter.template_node);
  }

  private async setTemplate(noderef) {
    let r=await this.alfrescoService.getFileContent(noderef);
    this.label_template=r;
    console.log("--------------r is ", r)
    this.label_template=this.label_template.replace("<p>","").replace("</p>","");
    console.log("PrinterService template is now ", this.label_template);

  }

  private fillTemplateValue(_tmp:string, _tag:string, _val:string):string {
    let s:any=String(_tmp);
    let _m=String("${args._tag}").split("_tag").join(_tag);
    s=s.split(_m).join(_val);
    var s3=s.split(_m);
    var s2=s.replace(_m, _val)
    //console.log("_____________",_tag, _m, s.split(_m).length, "original    ", s);
    return s;
  }
  public async printLabelObject(obj) {
    
    let temp=this.label_template;
    console.log("print data is ", obj, typeof(temp))
    try {
      if (obj.denumire==obj.suborganization) { obj.denumire=""; }
    } catch(e) { }
    for (var k in obj) {
      temp=this.fillTemplateValue(temp, k, obj[k]);
    }
    
    console.log("Label value is ", temp);
    this.dataService.printLabel(this.selectedPrinter.printer, temp, this.auth.user.token);
  }

  public async printShippinglObject(ob) {
    
    let temp=this.label_template;
    console.log("print data is ", ob, typeof(temp))
    for (var k in ob) {
      temp=this.fillTemplateValue(temp, k, ob[k]);
    }
  console.log("Label value is ", temp);
  //DECOMMENT THIS LINE FOR PRINTING
  this.dataService.printLabel(this.selectedPrinter.printer, temp, this.auth.user.token);
}

}

<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">RODOS</a>
  </div>
  <nb-select selected="corporate" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
 </div>
<div class="header-container">

  <nb-actions >
    <nb-action class="control-item parent">
     <nb-select *ngIf="!adminLogged && parentOrganizations.length>1" [selected]="parentOrganizations[0]._id" #organizationsSelect (selectedChange)="selectionChange($event)">
        <nb-option *ngFor="let org of parentOrganizations" [value]="org._id">{{org.denumire}}</nb-option>
      </nb-select>
    </nb-action>
    <nb-action class="control-item" *nbIsGranted="['view', 'user']" >
      
      <!-- <nb-search type="rotate-layout" placeholder="search customer"></nb-search> -->
      <button class="control-button" nbButton ghost [nbContextMenu]="[ { title:  this.ls.strings.profile, link:'/pages/client/user-profile' }, {title: this.ls.strings.menu_client_notification, link:'/pages/client/client-notification'}, { title: this.ls.strings.logout, data: { id: 'logout' }} ]" [class.on]="shuffle">
        <nb-icon icon="person-outline"></nb-icon>
        <span class="new_notification" *ngIf="newNotifications>0">{{newNotifications}}</span>
        {{this.auth.user.username}}
      </button>
    
    <a   style="margin-left:15px;height:36px" (click)="this.ls.changeLanguage('en')"><img style="height:inherit;cursor:pointer;" src='./assets/images/uk.png'></a>
    <a     style="margin-left:15px;height:39px" (click)="this.ls.changeLanguage('ro')"><img  style="height:inherit;cursor:pointer;" src='./assets/images/ro.png'></a>
  </nb-action>
  </nb-actions>
</div>
    
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { AlfrescoService } from '../../services/alfresco/alfresco.service';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';
//import { ENV } from '../../services/alfresco/alfresco.conf';
import { ENV } from '../../services/data/data.conf';
import { LocalDataSource } from 'ng2-smart-table';
import { UtilsService } from '../../services/utils/utils.service';
@Component({
  selector: 'ngx-folder-broswer',
  templateUrl: './folder-broswer.component.html',
  styleUrls: ['./folder-broswer.component.scss']
})
export class FolderBroswerComponent extends BaseComponent implements OnInit {
  @Input('external_path') external_path: any=null;
  @Input('in_client_overview') in_client_overview: boolean = false;
  public tableSource:any=[];
  private currentOrganizationName:String;
  loadingData=false;
  tableSettings: any = {  
  mode:'external',
  pager : {
    display: true,
    perPage: 10
  },
  actions: false, columns: {} 
  }
  broswerFullColumns= {};
  breadCrumbs: any[];
  organizationNames:any;
  private organizationIDS:any;
  constructor(public ls:LanguageService, 
    private dialogService:NbDialogService,
    private alfrescoService:AlfrescoService,
    private utilsService:UtilsService,
    private dataService:DataService, private router:Router, private route:ActivatedRoute, public auth: AuthService, public toastrService: NbToastrService) {
      super(dataService, auth, toastrService);
     }


  protected reinit(ob: any): void {
    this.breadCrumbs=[];
    this.initialize();
    this.showColumns();
  }
  async initialize() {
    //  this.organizationNames=await this.utilsService.getOrganizationNames();
    this.organizationNames=await this.utilsService.getOrganizations();
    if (this.external_path==null) {
      console.log("preff orgg is ", this.auth.user.preffered_organization)
    let org=await this.dataService.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
    console.log("orgg is ",org)
    try { this.currentOrganizationName=org.docs[0]._id; } catch(e) {}
    console.log("CURRENT ORGANIZATION IS ", this.currentOrganizationName)
    if (this.auth.isSystemRole()) {
      this.organizationIDS=[];
      for (var k in this.organizationNames) {
        //console.log("k ", k)
        this.organizationIDS.push(this.organizationNames[k]._id)
      }
      } else {
      this.organizationIDS=this.auth.user.organizations;
      if (this.in_client_overview) {
        let r=await this.dataService.getMainOrganizationAndBelow(this.auth.user.token, this.auth.user.preffered_organization, this.auth.user.organizations);
        this.organizationIDS=[];
        for (var k in r.org_list) {
          this.organizationIDS.push(r.org_list[k])
        }
      }
      //this.organizationIDS=[this.currentOrganizationName];
        console.log("ORGIDS is", this.organizationIDS)
      }
    } else { this.currentOrganizationName=this.external_path}

    console.log("------------------------ORGANIZTIONIDS IS ", this.organizationIDS)
    this.loadData(this.organizationIDS, true)
  }


  public refresh() {
    this.initialize();
  }
  async loadData(p, initial?) {
    this.loadingData=true;
    this.tableSource=[];
    let path="123"; 
    //console.log("---------initializing loadData ", p, this.external_path, "xxxx",p, initial)
    //if (p.length>0) {path="/"+p} else { path=p }
    path=p;
    let resp;
    let tableSource2
    if (initial) {
      resp=await this.alfrescoService.browseReportFolders(this.auth.user.token,p);
      tableSource2=resp;
      console.log("this table source company is ", tableSource2)
    } else {
      resp=await this.alfrescoService.browseReportFolders(this.auth.user.token,p);
      tableSource2=resp.children;
      console.log("this table source depart is ", this.tableSource)
    }
    console.log("is in initial mode is ", initial, this.breadCrumbs.length)
    // data is JSON, must show in table it's children - actual .children array
    // we need to keep the "id" of the folder which is actually a "breadcrumb"
    // parse the children, read them in objects. 
    // important fields are: noderef (need this for showing content), thumbnail - show it in the table maybe??, parent, mimetype, modified (for sort), size (in bytes), version
    // important!!!: if the child is a FOLDER - isFolder=true, we should be able to browse it using parent+'/'+filename
    if (this.breadCrumbs.length==0 && initial==false) {
      this.breadCrumbs.push({"noderef": path, "text": this.currentOrganizationName, "isFolder":true})
    }
    this.tableSource=[];
    for (var k in tableSource2) { 

      if ((tableSource2[k].isFolder && tableSource2[k].parent=="clients" && this.breadCrumbs.length==0) || this.breadCrumbs.length>0) {
        if (this.tableSource.filter(el=>el.noderef==tableSource2[k].noderef).length==0) {
          this.tableSource.push(tableSource2[k])
        } else { console.log("duplicate for ",k, tableSource2[k])}
      }
    };
    this.loadingData=false;
    console.log("table source ", this.tableSource);
  };

  showColumns() {
    this.tableSettings  = Object.assign({},  {
      mode:'external',
      pager : {
        display: true,
        perPage: 10
      },
      actions: false,
      columns: {
         trueName: {
          title: "Text",
          type: "html", 
          valuePrepareFunction: (ob, row) => {
            let nob=ob;
            //let t=this.organizationNames.filter(el=>el._id==ob)[0];

            return '<img width="32px" src="assets/filetypes/'+this.getIconFile(ob, row)+'" />'+nob; },

            //pentru filtrarea dupa denumire
            /* filterFunction: (cell?: any, search?: string): boolean => {
              let t = this.utilsService.resolveOrganizationName(cell);
              if (t) {
                return t.denumire.toLowerCase().includes(search.toLowerCase());
              }
              return false;
            },
            */
          }, 

        trueParent: {
          title: "Parent",
          type: "string"
        },
        mimetype: {
          title: "Mimetype",
          type: "string"
        },
        created: {
          title: "Created",
          type: "string"
        }
      },
    } );
  };

  getIconFile(name, row) {
    console.log("get icon file for ", name, row)
    let res="generic-file-32.png";
    let chunks=name.split(".");
    let ext=chunks[chunks.length-1];
    if (ext==name) {
        res="generic-folder-32.png";
    } else {
        
    }
    if (row.isFolder==true) {
      res="generic-folder-32.png";
    } else {
      res=ext+"-file-32.png";
    }
    return res;
  }

  isAtMainLevel():boolean {
    let bool=true;
    //console.log("is at main ", this.breadCrumbs)
    if (!this.breadCrumbs) return false;
    if (this.breadCrumbs.length>1) bool=false;
    return bool;
  }

  addCrumb(ob:any) {
    console.log("-======================== frigging crumb to add is ", ob, this.external_path)
    if (this.breadCrumbs.indexOf(ob)==-1) {
    this.breadCrumbs.push(ob);
    console.log(this.breadCrumbs,"adding crumb", ob)
    
    }
    let p='';
    for (var k in this.breadCrumbs) {
      p=p+"/"+this.breadCrumbs[k].text;
    }
    //this.loadData(p);
  } 
  popCrumb():any {
    let ob=this.breadCrumbs.pop();
    return ob;
  }

  goBack() {
    let c=this.popCrumb();
    console.log(c);
    let ar=[];
    ar.push(this.breadCrumbs[this.breadCrumbs.length-1].noderef);
    this.loadData(ar, true);
  }
  goHome() {
    this.breadCrumbs=[];
    this.loadData(this.organizationIDS, true);
  }
  onRowClick(event) {
     //this.tableSource=(this.broswerFullColumns)
    console.log("qqqq", this.external_path, "event is  ", event)
    if (event.data.isFolder==true) {
      
      if (this.external_path==null) {
        //this.loadData(event.data.parent+"/"+event.data.text);
        let ar=[];
        ar.push(event.data.id)
        this.loadData(ar, true);
        let _ttt=event.data.text;
        //let t=this.organizationNames.filter(el=>String(el._id).toUpperCase()==String(event.data.text).toUpperCase())[0];

       let t=this.utilsService.resolveOrganizationName(event.data.text);
      
        if (t) { _ttt=t.denumire; }
        this.addCrumb({"noderef": event.data.id, "text": _ttt, "isFolder":true})
      } else {
        if (event.data.parent=='clients') {
        //this.loadData(event.data.text);
        } else {
        //this.loadData(event.data.parent+"/"+event.data.text)
        } 
        //this.addCrumb({"noderef": event.data.text, "text": event.data.text, "isFolder":true})
      }
    } else {
      try {
        //let _src=this.alfrescoService.alf_url+ENV.API_GET_PDF_RENDITION+event.data.noderef+"&alf_ticket="+this.alfrescoService._adminTicket;
        //let _url=this.data.generatePDF(this.auth.user.token, event.data.noderef)
        let _src = ENV.API_ALFRESCO_GENERATE_PDF + event.data.noderef;
        this.dataService.openPDFFileInWindow(_src, this.auth.user.token)
      } catch(e) { console.log("some sort of error")}
    }
     // if event points to a folder, reload using the correct path and update the crumb
    // this.initialize(objectpath from object, like described above)
    // if not a folder, do something with it, eg try to open it using a pdf viewer or in a new window
  }

}

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbToastrService } from '@nebular/theme';
import { ENV } from '../../../@core/services/alfresco/alfresco.conf';
import { AlfrescoService } from '../../../@core/services/alfresco/alfresco.service';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { BaseComponent } from '../base/base-component';

@Component({
  selector: 'ngx-site-section',
  templateUrl: './site-section.component.html',
  styleUrls: ['./site-section.component.scss']
})
export class SiteSectionComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input("sectionPath") sectionPath:string='';
  sectionChildren:any[];
  constructor(public data:DataService, public auth:AuthService, public toastrService: NbToastrService, public ls:LanguageService, 
    private utilsService:UtilsService, private alfrescoService:AlfrescoService, private sanitizer:DomSanitizer, protected changeDetectorRef:ChangeDetectorRef) {
    super(data, auth, toastrService, ls, changeDetectorRef);
    console.log("--------------------------------------- this.site section", this)
  }
  ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');
  }

  protected reinit(ob){
    //console.log("SITE SECTION COMPONENT ", this.sectionPath)
    this.loadContent();
  }

  async loadContent() {
    if (this.sectionPath=='') return;
    let kids=await this.alfrescoService.clientPortalDashboard("/"+this.sectionPath);
    if (kids.children.length>0) {
      this.sectionChildren=await this.parseNodeKids(kids.children)
    }
  };

  async parseNodeKids(arr) {
    for(var k in arr) {
      if(arr[k].mimetype=='text/html') {
        let _t=await this.alfrescoService.viewFile(arr[k].id)
        arr[k].nodeContent=this.sanitizedText(_t)
      } else 
      if(arr[k].mimetype=='image/png')
      {
        arr[k].nodeUrl=this.alfrescoService.alf_url+ENV.API_GET_FILE_CONTENT+arr[k].id+"&alf_ticket="+this.alfrescoService._adminTicket;
      }
    };
    return arr;
  };
  sanitizedText(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text)
  };
}

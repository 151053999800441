import { Address } from "./address.model";
import { BaseModel } from "./base.model";
import { Representative } from "./representative.model";
import { Service } from "./service.model";

export class OrganizationModel extends BaseModel {
    public classified: Boolean;
    public cui: String;
    public denumire: String;
    public organization_code: String;
    public budget_code:any;
    public nrRegCom: String;
    public codPostal: String;
    public iban: String;
    public banca: String;
    public children:any[];
    public adresa_web: String;
    public parent:String;
    public representative: Representative;
    public shipping_representative: Representative;
    public ship_to_representative: Boolean;
    public billing_representative: Representative;
    public bill_to_representative: Boolean;
    public workplace_address:Address;
    public shipping_address:Address;
    public ship_to_work:Boolean;
    public billing_address: Address;
    public bill_to_work: Boolean;
    public services:Service[];
    public contract_valability: Date;
    public organzation_code: String;
    public enrolled:Boolean;

    constructor() {
        super();
        this.collection_name="organizations";
    } 

    protected generateInit() {
        this.classified = false;
        this.active = false;
        this.cui= "";
        this.denumire= "";
        this.organization_code= "";
        this.budget_code= {};
        this.nrRegCom= "";
        this.contract_valability= null;
        this.codPostal= "";
        this.iban= "";
        this.banca= "";
        this.children=[],
        this.adresa_web="";
        this.parent="";
        this.representative= new Representative();
        this.billing_representative= new Representative();
        this.shipping_representative= new Representative();
        this.workplace_address=new Address();
        this.shipping_address=new Address();
        this.billing_address=new Address();
        this.services= [];
        this.enrolled = false;
       
    }
}


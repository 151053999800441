import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { OrganizationModel } from '../../../@core/models/organization.model';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { BaseComponent } from '../base/base-component';
import { UserModel } from '../../../@core/models/user.model';
import { setInterval } from 'timers';
import { UnreturnedDosimetersComponent } from '../unreturned-dosimeters/unreturned-dosimeters.component';

@Component({
  selector: 'ngx-view-client-dashboard',
  templateUrl: './view-client-dashboard.component.html',
  styleUrls: ['./view-client-dashboard.component.scss']
})
export class ViewClientDashboardComponent extends BaseComponent implements OnInit {
  user: UserModel;
  isSystemUser:boolean=false;
  denumire =[];
  suborganizations =[];
  organization:OrganizationModel=new OrganizationModel();
  lateDosimeters:number=-1;
  organizationExposed:number=-1;
  currentOrganization: any;
  selectedSuborganizationId:"";
  @ViewChild('organizationSelect') organizationSelect;

  constructor(public dataService:DataService, public auth:AuthService, public toastrService: NbToastrService, public ls:LanguageService, public dialogService: NbDialogService,
    private utilsService:UtilsService, private router:Router) {
      super(dataService, auth, toastrService, ls)
     };

  protected reinit(ob){
    this.reinitialize();
  };
  async reinitialize() {
    await this.getUserDetails();
    await this.getCurrentOrganizationDetails();
    await this.getOrganizationDetails();
    await this.getSuborgNameById();
    await this.getSuborganizationExposed(this.auth.user.selected_suborganization)
  }
  async ngAfterViewInit() {
    this.isSystemUser=this.auth.isSystemRole();
  };

  organizationChange(id) {
    this.getSuborganizationExposed(id);
  };

  async getCurrentOrganizationDetails(){
    let current = await this.data.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
    if (current==undefined) return;
    this.currentOrganization = current.docs[0];
    await this.getSuborganizations();
     this.updateLateDosimeters();
  };

  async getSuborganizations() {
    let r = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);

    if (this.isSystemUser==false) {
  let r=await this.utilsService.getOrganizationNames();
  console.log("R issssssssssss ", r)
  let resp=r.filter(el=>el.parent==this.auth.user.preffered_organization);
  this.suborganizations = resp;
  console.log("Suborganization un user false are ", this.suborganizations)
  try{ this.auth.user.selected_suborganization=this.suborganizations[0]._id;} catch (e) {}
  try {} catch(e) {
  this.getSuborganizationExposed( this.auth.user.selected_suborganization);
  }


    }
    else {
      this.suborganizations = r;
      this.auth.user.selected_suborganization=this.suborganizations[0]._id;
      this.getSuborganizationExposed(r[0]._id);
      this.updateLateDosimeters();
    }
    
  };


// async getSuborganizations() {
//   let res = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);
//   if (this.isSystemUser==false) {
// console.log("PREFF ORG ISSSS ", this.auth.user.preffered_organization)
// let r=await this.utilsService.getOrganizationNames();
// console.log("R issssssssssss ", r)
// let resp=r.filter(el=>el.parent==this.auth.user.preffered_organization);
// this.suborganizations = resp;
// console.log("lenght is ", this.suborganizations)
// if(this.suborganizations.length >0) {
//   console.log("Suborganization un user false are ", this.suborganizations)
//   this.auth.user.selected_suborganization=this.suborganizations[0]._id;
//   this.getSuborganizationExposed(resp[0]._id);
//   this.updateLateDosimeters();
// } 
//   else {
//     this.suborganizations = res;
//     console.log("all suborgs are ", this.suborganizations)
//     this.auth.user.selected_suborganization=this.suborganizations[0]._id;
//     await this.getSuborganizationExposed(res[0]._id);
//     this.updateLateDosimeters();
//   }
// }
// };

  async updateLateDosimeters () {
    console.log(" auth selected suborg ", this.auth.user.selected_suborganization)
    if(this.auth.user.selected_suborganization !=undefined) {
      let res = await this.data.countCollection({ coll_name: "dose_record", "query" : { suborganizationId: this.auth.user.selected_suborganization, late: true, status: "shipped", active: true}}, this.auth.user.token);
      this.lateDosimeters = res.number;
    } else {this.lateDosimeters = 0}
  
  };

  onSuborganizationChange(selectedSuborganizationId: string) {
    this.auth.user.selected_suborganization = selectedSuborganizationId;
    this.updateLateDosimeters();
    this.getSuborganizationExposed(this.auth.user.selected_suborganization);
  }
  
  async getSuborganizationExposed(suborganizationId){
    this.selectedSuborganizationId=suborganizationId;
    let resp = await this.data.getOrganizatonPersons(suborganizationId, this.auth.user.token, true);
    this.organizationExposed=resp.persons.length;
    this.auth.user.selected_suborganization =suborganizationId; 
  };

  async getOrganizationDetails() {
    let resp = await this.data.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
    try {
    if(resp.docs[0]){
    this.organization=resp.docs[0]} 
    } catch(e) {}
    try {this.organization.contract_valability=this.utilsService.getFormattedDate(this.organization.contract_valability, 'dd-MM-yyyy')}
    catch(e) { return "-";}
  };

  show_wearers() {
    let suborg;
    suborg= this.selectedSuborganizationId;
    console.log("selected suborg is ", suborg)
    this.auth.user.selected_suborganization=suborg;
    this.router.navigateByUrl('pages/admin/suborganization-wearer');
  };

  async show_unreturned_dosi() {
    if(this.selectedSuborganizationId == undefined) {return}
    this.dialogService.open(UnreturnedDosimetersComponent, {
      context: {
        suborg: this.selectedSuborganizationId
      
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
      }
    })
  };

  async getUserDetails(){
    let r = await this.data.getUserDetails(this.auth.user.token, this.auth.user.username, true, true);
    this.user = r
    this.suborganizations=[];
    for (let k in this.user.organizations) {
      this.suborganizations.push(this.user.organizations[k]);
    };
     this.getSuborgNameById()
  };

  async getSuborgNameById() {
    let resp = await this.data.getSuborgNameById(this.auth.user.token, this.user, true);
    this.denumire =[];
    for (var k in resp.organizations.docs){
      this.denumire.push(resp.organizations.docs[k].denumire)
    };
  };

  isClient() {
    return this.auth.hasClientAdminRole();
   };

   async isAdmin():Promise<Boolean>{
    return this.auth.isAdmin();
  }

}

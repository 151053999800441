import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from "@nebular/theme";
import { AuthService } from "../../../@core/services/auth/auth.service";
import { DataService } from "../../../@core/services/data/data.service";
import { LanguageService } from "../../../@core/services/language/language.service";

@Component({
    template: ''
  })
export class BaseComponent implements OnInit, OnDestroy, AfterViewChecked {
    subscription: any;
    lsubscription: any;
    public title_key;
    protected position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    constructor(public data?: DataService, public auth?:AuthService, public toastrService?: NbToastrService, public ls?:LanguageService, protected changeDetector?:ChangeDetectorRef){
        
    }
    ngAfterViewChecked(): void {
        if (this.changeDetector!=null) 
        {
        this.changeDetector.detectChanges();
        }
      }
    ngOnInit(): void {
        this.lsubscription = this.ls.getLang().subscribe(ob=> {
            setTimeout(()=> {
                this.reinit(ob);
            }, 200)
            
        })
        this.subscription = this.data.getObjectData().subscribe(ob=> {
            setTimeout(()=> {
                console.log("on init ", ob)
            //    this.auth.user.preffered_organization=ob;
            this.reinit(ob)
            }, 200)
          })
          this.reinit(null)
    }

    protected reinit(ob){
    }

    ngOnDestroy(): void {
        if (this.subscription)
        this.subscription.unsubscribe();
        if (this.lsubscription) {
            this.lsubscription.unsubscribe();
        }
    }

    public async canAccess():Promise<Boolean> {
        return this.auth.canAccess(this.title_key, "read");
    }
    public async canWrite():Promise<Boolean> {
        let b=this.auth.canAccess(this.title_key, "write");
        if (!b) this.accessDenied();
        return b;
    }
    public async canEdit():Promise<Boolean> {
        let b=this.auth.canAccess(this.title_key, "edit");
        if (!b) this.accessDenied();
        return b;
    }
    public canDelete():Boolean {
        let b=this.auth.canAccess(this.title_key, "delete");
        console.log("can delete", b)
        if (!b) this.accessDenied();
        return b;
    }
    protected accessDenied() {
        this.showToast("warning", this.ls.strings.op_not_permited, this.ls.strings.inssuficient_rights)
    }
    public showToast(type: NbComponentStatus, title: string, body: string,buttonText?: string, buttonCallback?:()=>void) {
        const config = {
          status: type,
          destroyByClick: true,
          duration: 3000,
          hasIcon: true,
          position: this.position,
          preventDuplicates: true,
        };
        const titleContent = title ? ` ${title}` : "";
    
        let toast=this.toastrService.show(body, `${titleContent}`, config);
        console.log(title);
        if (buttonText && buttonCallback) {
       /*      toast.buttons = [
              {
                text: buttonText,
                role: 'cancel',
                handler: buttonCallback
              }
            ]; */
          }
      }


}
